<template>
  <div class="posts-loader" ref="root">

    <template v-if="paginator.posts.value?.length > 0">
      <div class="blog__posts-items row" :class="{ 'featured-view': featuredView }">
        <template v-for="post in paginator.posts.value" :key="post.id">
          <component :is="components[post.id]"></component>
        </template>
      </div>
    </template>
    <div v-else-if="paginator.loading.value">
      {{ $t('loading') }}
    </div>
    <div v-else-if="paginator.currentPage.value < 0" class="blog__posts__no-reuslt">
      <div class="blog__posts-search__no-result">
        <div class="row">
          <div class="col s12 m10 l8 xl6 offset-m1 offset-l2 offset-xl3">
            {{ $t('no_posts') }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="loadMore && !paginator.fetchedAll.value" class="blog__load-more button__wrapper button__ausrichtung__center">
      <button class="button button--standard" :class="{ loading: paginator.loading.value }" @click="paginator.loadMore">
        <span>Mehr laden</span>
        <svg width="19" height="20" viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
          <g fill="#313F37" fill-rule="nonzero">
            <path
              d="M16.427 0a.714.714 0 00-.714.714v2.609A9.265 9.265 0 00.003 9.997a.714.714 0 001.428 0A7.855 7.855 0 0115.08 4.695L11.917 5.75a.715.715 0 00.45 1.357l4.285-1.429a.714.714 0 00.489-.68V.715A.714.714 0 0016.427 0zM17.855 9.283a.714.714 0 00-.714.714A7.855 7.855 0 013.49 15.3l3.164-1.054a.715.715 0 00-.451-1.357l-4.285 1.428a.714.714 0 00-.488.68v4.284a.714.714 0 101.428 0v-2.608a9.265 9.265 0 0015.71-6.675.714.714 0 00-.714-.714z"></path>
          </g>
        </svg>
      </button>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.button.loading {
  opacity: 0.6;
}
</style>

<script lang="ts" setup>
import { defineComponent, PropType, ref, watch } from 'vue';
import { DiviTaxonomyDefinition, usePaginator } from '@/js/vue/composition/paginator';

const props = defineProps({
  taxonomies: {
    type: Array as PropType<Array<DiviTaxonomyDefinition | Array<DiviTaxonomyDefinition>>>,
    required: false,
    default: () => [],
  },
  paginatorOptions: {
    type: Object,
    required: true,
  },
  loadMore: {
    type: Boolean,
    default: () => true,
  },
  featuredView: {
    type: Boolean,
    default: () => true,
  },
});

const root = ref<HTMLElement>();

// Hold a state of all components to prevent re-rending the list when adding or filtering items.
const components = {};

const definePostComponent = (template: string) => defineComponent({
  name: 'post-content',
  template,
});

const paginator = usePaginator({
  ...(props.paginatorOptions as any),
  taxonomies: props.taxonomies,
  url: props.paginatorOptions.url,
  scrollToElementOnFilterRestore: root,
});

watch(paginator.posts.value, (posts) => {
  posts.forEach(post => {
    if (!components.hasOwnProperty(post.id)) {
      components[post.id] = definePostComponent(post.content);
    }
  });
});
</script>
