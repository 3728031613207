<template>
  <button @click="loadMore" v-if="hasMore && !hide" :disabled="isFetching" v-bind="$attrs" :class="{loading: isFetching}">
    <slot>Mehr Laden</slot>
  </button>
</template>

<script>
import axios from 'axios'
import makeVue from "@/js/vue/vue.js";

export default {
  props: {
    container: {
      type: String,
      required: true
    },
    query: {
      type: String,
      required: false,
      default: () => location.search
    },
    initialPage: {
      type: Number,
      default: () => 0
    },
    fetch: {
      type: Boolean,
      default: () => false
    },
    perPage: {
      type: Number,
      required: true
    },
    totalItems: {
      type: Number,
      required: false,
      default: () => 0
    },
    insert: {
      type: Boolean,
      required: false,
      default: () => true
    },
    hide: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    page: 0,
    isFetching: false,
    fetchOnce: false,
    updatedTotalItems: 0
  }),
  computed: {
    currentNumberOfItems() {
      return (this.page + 1) * this.perPage;
    },
    hasMore() {
      return this.currentNumberOfItems < this.updatedTotalItems;
    },
    nextPage() {
      return this.fetchOnce ? this.page : this.page + 1;
    }
  },
  mounted() {
    this.page = this.initialPage
    this.fetchOnce = this.fetch;
    this.updatedTotalItems = +this.totalItems;

    if (this.fetch) {
      this.loadMore();
    }
  },
  methods: {
    loadMore() {
      if (!this.fetchOnce && (!this.hasMore || this.isFetching)) {
        return;
      }

      this.isFetching = true;

      const $container = document.querySelector(this.container);

      $container.classList.add('loading');

      axios.get('/wp-json/wly/blog' + this.query, {
        params: { 
          page: this.nextPage,
          per_page: this.perPage
        }
      }).then(res => {
        const { posts, page, total_items } = res.data;

        if (this.insert) {
          for (let post of posts) {
              const el = document.createElement('div');
              el.innerHTML = post.content;
              $container.appendChild(el.firstChild);
          }

          this.$nextTick(() => {
            for (const node of ($container.querySelectorAll('[data-vue-instance]') ?? [])) {
              makeVue(node);
            }

            for (const node of ($container.querySelectorAll('[data-vue-instance-replace]') ?? [])) {
              makeVue(node, true);
            }
          })
        }

        if (posts.length > 0 ) {
          this.page = page;
        }

        if (total_items === 0) {
          $container.classList.add('no-posts');
        }

        this.updatedTotalItems = total_items;
        this.isFetching = false;
        this.fetchOnce = false;
        $container.classList.remove('loading');

        window.dispatchEvent(new CustomEvent('LoadMorePostsDone'));
      });
    }
  }
}
</script>
