import YoutubePlayer from './YoutubePlayer.vue';
import LoadMore from "./LoadMore.vue";
import PostsLoader from "./Loaders/PostsLoader.vue";
import PostLikes from "./PostLikes.vue";

export const registerComponents = (app) => {
    app.component('wly-youtube', YoutubePlayer);
    app.component('wly-load-more', LoadMore);
    app.component('posts-loader', PostsLoader);
    app.component('post-likes', PostLikes);
};
