// @ts-ignore
import $, { type } from 'jquery';
import { computed, nextTick, onMounted, reactive, Ref, ref, watch } from 'vue';
import { DateTime } from 'luxon';

export type TaxonomySlug = string;
export type Paginator = ReturnType<typeof usePaginator>;

interface TaxonomyFilterItems {
    [key: TaxonomySlug]: number[] | boolean;
}

interface FlattenTaxonomyFilter {
    [key: string]: string;
}

export interface PaginatorOptions {
    url: string;
    initialPage: number;
    lastPage: number;
    perPage: number;
    totalItems: number;
    hideFilter?: boolean;
    requestTermCounts?: boolean;
    taxonomies?: Array<DiviTaxonomyDefinition | Array<DiviTaxonomyDefinition>>;
    scrollToElementOnFilterRestore?: Ref<HTMLElement | undefined>;

    // If only one filter is allowed to be applied at once
    singleFilter?: boolean;

    // If the first term should be selected automatically, if the filter is single filter mode.
    requireDefaultFilter?: boolean;
}

export interface SelectedTaxonomyFilter {
    label: string;
    taxonomy: string;
    term?: number;
}

export interface DiviTermDefinition {
    term_id: number;
    taxonomy: string;
    name: string;
    slug: string;
}

export interface DiviTaxonomyDefinition {
    slug: string;
    label: string;
    terms: Array<DiviTermDefinition>;
}

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
const getParams = function (url) {
    const params = {};
    let parsedParams = url;
    let needsLoop = false;

    if (typeof url === 'string') {
        const query = url.split('?')[1] || '';

        parsedParams = Object.fromEntries(new URLSearchParams(query));
    }

    Object.keys(parsedParams).forEach((key) => {
        let value = parsedParams[key];

        try {
            value = JSON.parse(value);
        } catch (_) {
            if ((+value).toString() === value) {
                value = +value;
            }
        }

        // Lets decode an URL contain an array-like syntax for params
        const matches = key.match(/^(.*)\[([\dA-Za-z-_]*)\]$/);

        if (matches) {
            delete params[key];

            const currentKey = matches[2];
            const parentKey = matches[1];

            Object.assign(params, {
                [parentKey]: {
                    ...(params[parentKey] ?? {}),
                    [currentKey]: value,
                },
            });

            needsLoop = true;
        } else {
            params[key] = value;
        }
    });

    return needsLoop ? getParams(params) : params;
};

/**
 * Remove the query string from a url.
 *
 * @param {string} url
 */
const removeParams = function (url) {
    return url.split('?')[0] || '';
};

export const usePaginator = (options: PaginatorOptions) => {
    const {
        url, // The URL to the API of the paginator instance
        lastPage: _lastPage, // The last page number
        hideFilter = false,
        perPage = 10,
        totalItems: _totalItems,
        requestTermCounts = false,
        taxonomies = [],
        scrollToElementOnFilterRestore = false,
        singleFilter = false,
        requireDefaultFilter = false,
    } = options;

    const loading = ref<boolean>(false);
    const posts = ref<Array<any>>([]);

    const fromDate = ref<DateTime>(null);
    const toDate = ref<DateTime>(null);

    const flatTaxonomies = computed<Array<DiviTaxonomyDefinition>>(() => taxonomies.flat(1));
    const totalItems = ref<number | undefined>(_totalItems);
    const taxonomyFilter = ref<FlattenTaxonomyFilter>({});
    const taxonomyFilterItems = ref<TaxonomyFilterItems>({});
    const hasActiveFilter = computed<boolean>(() => Object.values(taxonomyFilter.value).filter(Boolean).length > 0);
    const termCounts = reactive({});

    const currentPage = ref<number>(-1);
    const lastPage = ref<number | undefined>(_lastPage);

    const pages = computed(() => [...Array((lastPage.value ?? 0) + 1).keys()]);
    const hasPrevPage = computed(() => currentPage.value > 0);
    const hasNextPage = computed(() => currentPage.value < lastPage.value);
    const prevPage = computed(() => hasPrevPage.value ? currentPage.value - 1 : null);
    const nextPage = computed(() => hasNextPage.value ? currentPage.value + 1 : null);
    const fetchedAll = computed(() => currentPage.value !== -1 && currentPage.value >= lastPage.value);

    const loadMore = () => hasNextPage.value && fetchPosts({append: true, page: nextPage.value});
    const toFirstPage = () => fetchPosts({page: 0});
    const toLastPage = () => fetchPosts({page: lastPage.value});
    const toPrevPage = () => hasPrevPage.value && fetchPosts({page: prevPage.value});
    const toNextPage = () => hasNextPage.value && fetchPosts({page: nextPage.value});
    const toPage = (page) => fetchPosts({page});

    const selectedTaxonomyFilters = computed<Array<SelectedTaxonomyFilter>>(() => {
        const items = [];

        flatTaxonomies.value.forEach((taxonomy) => {
            const filterItem = taxonomyFilterItems.value[taxonomy.slug];
            if (filterItem === true) {
                items.push({
                    label: taxonomy.label,
                    taxonomy: taxonomy.slug,
                });
            }

            if (Array.isArray(filterItem) && filterItem.length > 0) {
                taxonomy.terms.forEach((term) => {
                    if (filterItem.includes(term.term_id)) {
                        items.push({
                            label: term.name,
                            taxonomy: taxonomy.slug,
                            term: term.term_id,
                        });
                    }
                });
            }
        });

        return items;
    });

    const isActiveTaxonomyFilter = computed(() => (taxonomy: string, term?: number): boolean => {
        const value = taxonomyFilterItems.value[taxonomy];
        if (Array.isArray(value)) {
            if (!term) {
                return value.length === flatTaxonomies.value.find((t) => t.slug === taxonomy)?.terms.length;
            }

            return value.indexOf(term) >= 0;
        }

        if (typeof value === 'boolean' || value === undefined) {
            return Boolean(taxonomyFilterItems.value[taxonomy]) || false;
        }

        return false;
    });

    const countActiveTaxonomyFilters = computed(() => (taxonomy: string): number => {
        const value = taxonomyFilterItems.value[taxonomy];

        if (Array.isArray(value)) {
            return value.length;
        }

        if (value === true) {
            return flatTaxonomies.value.find((t) => t.slug === taxonomy).terms?.length ?? 0;
        }

        return 0;
    });

    const getSelectedTermIdsByTaxonomy = (taxonomySlug: string): number[] => {
        const items = [];

        flatTaxonomies.value.forEach((taxonomy) => {
            if (taxonomy.slug !== taxonomySlug) {
                return;
            }

            const filterItem = taxonomyFilterItems.value[taxonomy.slug];

            if (Array.isArray(filterItem) && filterItem.length > 0) {
                taxonomy.terms.forEach((term) => {
                    if (filterItem.includes(term.term_id)) {
                        items.push(term.term_id);
                    }
                });
            }
        });

        return items;
    };

    const setTaxonomyFilter = (taxonomy: string, term?: number) => {
        if (singleFilter) {
            resetFilter();
        }

        const value = taxonomyFilterItems.value[taxonomy];

        if (!term) {
            taxonomyFilterItems.value[taxonomy] = taxonomyFilterItems.value[taxonomy] !== true;
        } else {
            const terms = Array.isArray(value) ? value : [];
            const termIndex = terms.indexOf(term);

            if (termIndex >= 0) {
                terms.splice(termIndex, 1);
            } else {
                terms.push(term);
            }

            taxonomyFilterItems.value[taxonomy] = terms;
        }

        applyFilter();
        toFirstPage();
    };

    const resetFilter = () => {
        Object.keys(taxonomyFilterItems.value).forEach((key) => delete taxonomyFilterItems.value[key]);
        applyFilter();
        toFirstPage();
    };

    const applyFilter = () => {
        flatTaxonomies.value
            .map((t) => t.slug)
            .forEach((taxonomy) => {
                let term = null;
                const selectedItems = taxonomyFilterItems.value[taxonomy];

                if (selectedItems === true) {
                    term = flatTaxonomies.value
                        .find((t) => t.slug === taxonomy)
                        ?.terms.map((t) => t.term_id)
                        .join(',');
                } else if (Array.isArray(selectedItems) && selectedItems.length > 0) {
                    term = selectedItems.join(',');
                }

                const terms = (Array.isArray(term) ? term : [term]).filter((t) => !!t);

                taxonomyFilter.value[taxonomy] = terms.length > 0 ? terms.join(',') : null;
            });
    };

    const saveStateToUrlQuery = () => {
        return;

        const params = new URLSearchParams(window.location.search);

        const terms = {};

        Object.keys(taxonomyFilterItems.value).forEach((slug) => {
            const value = taxonomyFilterItems.value[slug];

            if (value === true || (Array.isArray(value) && value.length > 0)) {
                terms[slug] = value;
            }
        });

        params.set('current-page', (currentPage.value + 1).toString());

        if (Object.keys(terms).length === 0) {
            params.delete('terms');
        } else {
            params.set('terms', btoa(JSON.stringify(terms)));
        }

        if (Array.from(params).length === 0) {
            history.replaceState(history.state, document.title, location.pathname);
            return true;
        }
        history.replaceState(history.state, document.title, [location.pathname, params.toString()].join('?'));
        return true;
    };

    const restoreStateFromUrlQuery = () => {
        return false;

        let restored = false;
        const params = new URLSearchParams(window.location.search);
        const termsParam = getTermsFromUrlParams(params);
        const currentPageParam = Math.max(Number(params.get('current-page') ?? 1) - 1, 0);
        if (Object.keys(termsParam).length > 0) {
            Object.assign(taxonomyFilterItems.value, termsParam);

            if (scrollToElementOnFilterRestore) {
                $('html, body').animate({
                    scrollTop: $(scrollToElementOnFilterRestore.value).offset().top - $('header').height() - 150
                }, 1000);
            }

            restored = true;
        }

        if (currentPageParam > 0) {
            restored = true;
        }

        if (restored) {
            applyFilter();
            toPage(currentPageParam);
        }

        return restored;
    }

    const getTermsFromUrlParams = (params) => {
        return JSON.parse(atob(params.get('terms') ?? '') || '{}');
    };

    const getRequestedTermsCount = () =>
        taxonomies
            .flat(1)
            .map((taxonomy) => ({
                [taxonomy.slug]: taxonomy.terms.map((t) => t.term_id),
            }))
            .reduce((prev, current) => ({...prev, ...current}), []);

    const getCountForTerm = (taxonomy, term) => computed(() => (termCounts[taxonomy] ? termCounts[taxonomy][term] ?? -1 : -1));

    /**
     * The query params that will be used for the request to fetch more posts.
     *
     * @returns {{per_page, page: *}}
     */
    const makeQueryParams = ({page}): any => ({
        ...getParams(url),
        per_page: perPage,
        page,
        has_active_filter: hasActiveFilter.value,
        from: fromDate.value?.toFormat('yyyy-LL-dd'),
        to: toDate.value?.toFormat('yyyy-LL-dd'),
        ...(requestTermCounts
            ? {
                request_terms_count: getRequestedTermsCount(),
            }
            : {}),
    });

    const setFromDate = (date: DateTime | null, fetch = true) => {
        fromDate.value = date;
        fetch && toPage(0);
    };

    const setToDate = (date: DateTime | null, fetch = true) => {
        toDate.value = date;
        fetch && toPage(0);
    };

    const resetDates = () => {
        fromDate.value = null;
        toDate.value = null;
        toPage(0);
    };

    const setPublishedInYear = (year: number | null) => {
        if (year === null) {
            resetDates();
        } else {
            setFromDate(
                DateTime.fromObject({
                    year,
                    month: 1,
                    day: 1,
                }),
                false
            );

            setToDate(
                DateTime.fromObject({
                    year,
                    month: 12,
                    day: 31,
                }),
                false
            );

            toPage(0);
        }
    };

    /**
     * Pulls the posts for the next page and inserts them into the container, if not disabled.
     *
     * @returns {Promise<Array<any>>}
     */
    const fetchPosts = (options: Record<string, any> = {}) => {
        const {
            append = false,
            reset = false,
        } = options;

        let requestedPage = options.page ?? 0;

        if (reset || (requestedPage >= 0 && requestedPage > lastPage.value) || requestedPage === null) {
            requestedPage = 0;
        }

        if (loading.value) {
            return Promise.resolve([]);
        }

        if (fetchedAll.value && append) {
            return Promise.resolve([]);
        }

        let _url = removeParams(url);
        let _queryParams = makeQueryParams({page: requestedPage});

        if (hasActiveFilter.value) {
            // Clear the params so that we can add the filter attributes
            _queryParams.terms = {};

            Object.keys(taxonomyFilter.value).forEach((name) => {
                const value = taxonomyFilter.value[name];

                if (value) {
                    _queryParams.terms[name] = value;
                }
            });
        } else if (!hideFilter) {
            // When a filter is visible, but no filter is selected we want to find posts containing any of the terms,
            // not all. This is necessary because included taxonomies/terms could be limited by the divi module.
            _queryParams['taxonomy_relation'] = 'or';
        }

        if (_queryParams.page <= 0 && append) {
            _queryParams.min_items = Math.max(_queryParams.per_page, posts.value.length);
        }

        loading.value = true;

        return $.get(_url, _queryParams)
            .then((data) => {
                const {posts: _posts, page: _page, last_page: _last_page, total_items: _total_items} = data;

                if (reset || !append) {
                    posts.value.splice(0, posts.value.length);
                }

                posts.value.push(..._posts);

                totalItems.value = _total_items;
                lastPage.value = _last_page;
                currentPage.value = _page;
                loading.value = false;

                if (_page <= 0) {
                    Object.assign(termCounts, data.term_counts);
                }

                saveStateToUrlQuery();

                return _posts;
            })
            .catch(() => {
                loading.value = false;
            })
            .then(() => {
                if (currentPage.value > lastPage.value) {
                    toFirstPage();
                }
            });
    };

    onMounted(() => {
        if (!restoreStateFromUrlQuery()) {
            // If the filter should only have a single active filter we will pre-selected the first term.
            if (requireDefaultFilter) {
                const firstTerm =
                    taxonomies
                        .flat(1)
                        .map((tax) => tax.terms)
                        .flat(1)[0] ?? null;

                if (firstTerm) {
                    setTaxonomyFilter(firstTerm.taxonomy, firstTerm.term_id);
                }
            }

            applyFilter();
            toFirstPage();
        }
    });

    return {
        taxonomies,
        setTaxonomyFilter,
        requestTermCounts,
        setFromDate,
        setToDate,
        setPublishedInYear,
        resetDates,
        loading,
        currentPage,
        hasPrevPage,
        hasNextPage,
        pages,
        lastPage,
        totalItems,
        posts,
        fetchedAll,
        termCounts,
        getCountForTerm,

        taxonomyFilterItems,
        flatTaxonomies,
        selectedTaxonomyFilters,
        isActiveTaxonomyFilter,
        countActiveTaxonomyFilters,
        getSelectedTermIdsByTaxonomy,
        resetFilter,

        loadMore,
        toFirstPage,
        toLastPage,
        toPrevPage,
        toNextPage,
        toPage,
    };
};