import $ from 'jquery';
import 'lazysizes';

$(() => {
    lazySizes.init();

    // Scroll to
    $(document).on('click', 'a[href]:not([href^="mailto\\:"], [href$="\\#"])', function (event) {
        if ($($.attr(this, 'href')).length && $.attr(this, 'href') !== '#wlymmenu') {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $('header').height()
            }, 1000);
        }
    });

    // Search opener
    $('.search__opener').click(function() {
        $(this).parent().addClass('open');
        $('body').addClass('searchisopen');
    });
    $('.search__closer').click(function() {
        $(this).parent().removeClass('open');
        $('body').removeClass('searchisopen');
    });

    // Get Height of Subnavi Element
    function thisHeight(){
        return $(this).height();
    }

    $('.cleanhtmlnav li.parent').hover(function() {
        $(".submenu__wrapper").height(function() {
            return Math.max.apply(Math, $(this).find(".submenu__wrapper").map(thisHeight));
        });
    });

    // Akkordeon
    $('.wly-accordeon__title').click(function() {
        $(this).parent().toggleClass('active');
        $(this).parent().siblings('.wly-accordeon__item').removeClass('active');

        $('.wly-accordeon__item').each(function() {
            if ($( this ).hasClass('active')) {
                $(this).children('.wly-accordeon__content').slideDown();
            } else {
                $(this).removeClass('active');
                $(this).children('.wly-accordeon__content').slideUp();
            }
        });
    });

    // Navigation Touch Geräte
    $('ul.navbar-nav > li.parent > a').on('touchstart', function(e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });
});
