<template>
  <slot v-bind="{ handleClick, hasLiked, likes }"></slot>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import axios from 'axios';
import { usePostLikesStore } from '@/js/vue/store';
import { storeToRefs } from 'pinia';

const props = defineProps({
  postId: {
    type: Number,
    required: true,
  }
});

const userId = (() => {
  if (localStorage.likesUserId) {
    return localStorage.likesUserId;
  }

  var s = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  localStorage.likesUserId = Array(24).join().split(',').map(function () {
    return s.charAt(Math.floor(Math.random() * s.length));
  }).join('');

  return localStorage.likesUserId;
})();

const store = usePostLikesStore(props.postId)();

const { likes, hasLiked } = storeToRefs(store);

if (props.postId) {
  axios.post(`/wp-json/!/post-likes/${props.postId}`, {
    userId
  }).then((res) => {
    likes.value = res.data.likes;
    hasLiked.value = res.data.has_liked;
  });
}

const handleClick = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();

  if (likes.value === null || likes.value === undefined) {
    return;
  }

  likes.value = null;

  axios.post(hasLiked.value ?
      `/wp-json/!/post-likes/${props.postId}/remove-like` :
      `/wp-json/!/post-likes/${props.postId}/add-like`
    , {
      userId
    }).then((res) => {
    likes.value = res.data.likes;
    hasLiked.value = res.data.has_liked;
  });
};
</script>