import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';
import {registerComponents} from './components/index.js';
import mitt from 'mitt';
import {Settings} from 'luxon';
import {makeI18nConfig} from './i18n.js';
import {createPinia} from "pinia";

const pinia = createPinia()

export default (el, replace = false) => {
    const i18nConfig = makeI18nConfig();
    const i18n = createI18n(i18nConfig);

    const app = createApp({
        data: () => ({
            // Make i18n available in custom elements, since they are not initialized with the custom vue options beneath
            i18n: i18nConfig,
            $wp: window.wp_data || {},
        }),
        mounted() {
            Settings.defaultLocale = this.i18n.locale?.toLowerCase().replace('_', '-') || 'de-ch';
        },
        methods: {
            ellipsis(value, length) {
                return value.length > length ? `${value.substring(0, length)}...` : value;
            },
        },
    });

    app.config.globalProperties.$bus = app.config.globalProperties.$bus || mitt();
    app.use(pinia);
    app.use(i18n);

    registerComponents(app);

    if (replace) {
        app.mount(el).$nextTick(() => {
            const nodes = Array.from(el.childNodes);

            const root = el.parentNode;
            el.replaceWith(nodes.shift());

            nodes.forEach((node) => {
                root.appendChild(node);
            });
        });
    } else {
        app.mount(el);
    }

    el.removeAttribute('data-vue-instance');
    el.removeAttribute('data-vue-instance-replace');

    return app;
};
